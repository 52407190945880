import React from 'react'
import { Grid } from '@hub/grid'
import { Text } from '@hub/text'
import { CoreContainer } from '@hub/core-container'
import { Box } from '@hub/box'
import { H3 } from '@hub/heading'
import { Link } from '@hub/link'
import { sortBy } from 'lodash'
import { MenuLink } from '@hub/navigation'
import MagicLink from '../magic-link'
import Tile from './tile'
import { Store } from '@scentregroup/shared/types/posts'
import getStoreMapLink from '@scentregroup/shared/helpers/get-store-map-link'

interface RetailerStoresTileProps {
  retailer: {
    id?: string | null
    title: string
    slug: string
  }
  stores: Store[]
  state?: string
}

interface StoreTileProps {
  title: string
  url: string
  mapUrl: string | null
  address: string | null
  landmark: string | null
  isLastChild: boolean
}

const StoreTile: React.FC<React.PropsWithChildren<StoreTileProps>> = ({
  title,
  url,
  mapUrl,
  address,
  landmark,
  isLastChild,
}) => (
  <Tile
    isLastChild={isLastChild}
    key={title}
    sxInner={{ gap: ['spacing-sm', null, 'spacing-md'] }}
  >
    <>
      <MagicLink href={url}>
        <Text isBodyText align="center" tone="strong" decoration="underline">
          {title}
        </Text>
      </MagicLink>
      <Box
        minHeight={'size-5'}
        paddingTop="spacing-sm"
        paddingLeft="spacing-sm"
        paddingRight="spacing-sm"
      >
        <Text isBodyText noOfLines={1} align="center">
          {address}
        </Text>
      </Box>
      <Box
        paddingLeft="spacing-sm"
        paddingRight="spacing-sm"
        paddingBottom="spacing-sm"
      >
        <Text isBodyText noOfLines={1} align="center">
          {landmark}
        </Text>
      </Box>
    </>
    {mapUrl && (
      <Link href={mapUrl}>
        <Text isBodyText decoration="underline">
          See on map
        </Text>
      </Link>
    )}
  </Tile>
)

const RetailerStoresTileList: React.FC<
  React.PropsWithChildren<RetailerStoresTileProps>
> = ({ retailer, stores, state }) => {
  const unsortedStoreTilePropsList = stores.map(store => ({
    title: store.centre.title,
    address: store.physicalAddress,
    landmark: store.landmarkDescription,
    url: `/${store.centre.slug}/store/${store.id}/${store.slug}`,
    mapUrl: getStoreMapLink(store.centre, { ...store, sys: { id: store.id } }),
  }))
  const tilePropsList = sortBy(unsortedStoreTilePropsList, [
    'title',
    'address',
    'landmark',
  ])

  return (
    <CoreContainer>
      <Box>
        <H3 as="h2" sx={{ marginBottom: 'spacing-md' }} align={'center'}>
          Visit {retailer.title} in {state ? state : 'centre'}
        </H3>
        <Grid
          templateColumns={[
            'repeat(auto-fit, 45%)',
            'repeat(auto-fit, 30%)',
            'repeat(auto-fit, 22%)',
            'repeat(auto-fit, 15%)',
          ]}
          templateRows={'1fr, 1fr, 1fr'}
          rowGap={['spacing-sm', 'spacing-sm', 'spacing-md', 'spacing-md']}
          columnGap={[
            'spacing-sm',
            'spacing-sm',
            'spacing-none',
            'spacing-none',
          ]}
          sx={{
            justifyContent: 'center',
            alignItems: 'stretch',
            gridAutoRows: '1fr',
            justifyItems: 'stretch',
            ['@media screen and (min-width: 48em) and (max-width: 62em)']: {
              ['*:nth-child(4n)']: {
                borderRightWidth: 0,
              },
            },
            ['@media screen and (min-width: 62em) ']: {
              ['*:nth-child(6n)']: {
                borderRightWidth: 0,
              },
            },
          }}
        >
          {tilePropsList.map((props, key) => (
            <StoreTile
              {...props}
              isLastChild={tilePropsList.length - 1 === key}
              key={key}
            />
          ))}
        </Grid>
        {state && retailer.id ? (
          <Box sx={{ marginTop: 'spacing-md' }}>
            <MenuLink
              href={`/store/${retailer.id}/${retailer.slug}`}
              sx={{
                display: 'block',
                width: 'size-lg',
                margin: '0 auto',
              }}
            >
              <Text fontSize="font-md" align="center" decoration="underline">
                See all centres
              </Text>
            </MenuLink>
          </Box>
        ) : null}
      </Box>
    </CoreContainer>
  )
}

export default RetailerStoresTileList
