import { get } from 'lodash'
import { stringifyUrl } from 'query-string'

interface Centre {
  featureFlags?:
    | {
        feature: string
        value: string
      }[]
    | null
  slug: string
}

interface Store {
  legacyIdentifier?: number | null
  sys: { id: string }
  slug: string
}

const FEATURE_FLAG_HIDE_STORE_SEE_ON_MAP_LINK = 'hideStoreSeeOnMapLink'

export default function getStoreMapLink(
  centre: Centre,
  store: Store
): string | null {
  const { slug, legacyIdentifier } = store
  const entryId = store.sys.id
  const featureFlags = get(centre, 'featureFlags') || []
  const hideStoreSeeOnMapLink = featureFlags.some(
    flag =>
      flag.feature === FEATURE_FLAG_HIDE_STORE_SEE_ON_MAP_LINK &&
      flag.value === 'true'
  )
  const centreMapPath = `/${centre.slug}/centre-map/${legacyIdentifier}/${slug}`

  const storeMapLink = hideStoreSeeOnMapLink
    ? null
    : stringifyUrl({ url: centreMapPath, query: { entryId } })
  return storeMapLink
}
